* {
    box-sizing: border-box;
}

.provider {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.20);
    display: flex;
    max-width: 100%;
    margin: 20px;
    overflow: hidden;
}

.provider h6 {
    opacity: 0.6;
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.provider h2 {
    letter-spacing: 1px;
    margin: 10px 0;
}

.provider-preview {
    max-width: 350px;
    background-size: cover !important;
    width: 350px;
}

.provider-preview a {
    color: #fff;
    display: inline-block;
    font-size: 12px;
    opacity: 0.6;
    margin-top: 30px;
    text-decoration: none;
}

.provider-info {
    padding: 10px;
    position: relative;
    width: 100%;
}

.progress-container {
    position: absolute;
    top: 30px;
    right: 30px;
    text-align: right;
    width: 150px;
}
.progress-container .progress-text .fa-times {
    font-size: 18px;
    transition: all .2s ease;
    cursor: pointer;
}

.progress-container .progress-text .fa-times:hover {
    font-size: 22px;
    color: red;
}
.provider-container .provider-info .provider-attrs-box {
    height: 110px;
    overflow-y: scroll;
    font-size: 1.5rem;
}
